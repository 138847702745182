
import { Component } from "vue-property-decorator";
import Table from "@/components/Table.vue";
import ManagementFooter from "@/components/ManagementFooter.vue";
import Header from "@/components/Header.vue";
import CheckAdminLogin from "@/utils/CheckAdminLogin";
import FacilityService from "@/services/facilities";
import { OperatorData } from "@/@types/management";
import { deleteNullProp } from "@/utils/Util";
import Button from "@/components/Button.vue";

@Component({
  name: "AdminManagement",
  components: {
    Table,
    Header,
    ManagementFooter,
    Button,
  },
})
export default class AdminManagement extends CheckAdminLogin {
  private search = "";
  private facilityHeaders = [
    { text: "アカウントID", value: "id" },
    { text: "名前", value: "name" },
    { text: "メールアドレス", value: "email" },
  ];
  private users = [];

  private admins = [];

  private loading = false;

  private tableLoading = false;

  private selectedInfo: OperatorData | null = null;
  private showModal(name: string) {
    this.$modal.show(name);
  }

  private closeModal(name: string) {
    this.$modal.hide(name);
  }
  private openDetailInfo(info: any) {
    this.selectedInfo = info;
    this.showModal("delete");
  }

  private async getOperators() {
    const res = await FacilityService.getOperators();
    this.users = res.data.data.filter((user: OperatorData) => user.role !== "admin");
    this.admins = res.data.data.filter((user: OperatorData) => user.role === "admin");
  }

  private async addAdmin(data: OperatorData) {
    try {
      this.loading = true;
      const operator = data;
      const id = operator.id;
      operator.role = "admin";
      const payload = deleteNullProp(operator);
      await FacilityService.updateOperator(id, payload);
      await this.getOperators();
      this.loading = false;
    } catch (error: any) {
      this.loading = false;
      if (error.response.data.error_msg.includes("Not Found operator")) {
        this.showModal("add-error");
      }
      throw new Error(error);
    }
  }

  private async deleteAdmin() {
    try {
      this.loading = true;
      const operator = this.selectedInfo;
      const id = operator?.id;
      if (operator) operator.role = "operator";
      const payload = deleteNullProp(operator);
      if (id) await FacilityService.updateOperator(id, payload);
      await this.getOperators();
      this.loading = false;
      this.closeModal("delete");
    } catch (error: any) {
      this.loading = false;
      if (error.response.data.error_msg.includes("Not Found operator")) {
        this.closeModal("delete");
        await this.getOperators();
      }
      throw new Error(error);
    }
  }

  private async mounted() {
    this.tableLoading = true;
    await this.getOperators();
    this.tableLoading = false;
  }
  private usersCustomFilter(value: any, search: string, item: any) {
    return (
      !!search &&
      (item.last_name?.concat(item.first_name).includes(search) ||
        item.first_name.includes(search) ||
        item.id.includes(search) ||
        item.email.includes(search))
    );
  }
}
